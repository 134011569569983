import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import {
	ApiService,
	CaixaService,
	EtapasPadraoService,
	ExcelService,
	ModalService,
	EtapasService,
	CidadesService,
	EstadosService,
	MessageService,
	TarefasService,
	SetoresService,
	NiveisUrgenciaService,
	ClientesService,
	ProjetosService,
	DashboardService,
	PropostasService,
	OrcamentosService,
	PermissoesService,
	PromotoresService,
	RelatoriosService,
	CustoInternoService,
	FornecedoresService,
	OrdemServicoService,
	TemplatePecaService,
	TiposProjetoService,
	ColaboradoresService,
	FormaPagamentoService,
	OrcamentoPecasService,
	TabelaPostagensService,
	TipoRecorrenciaService,
	FormaRecebimentoService,
	ProdutosTerceiroService,
	UsuariosClientesService,
	EtapasRecorrentesService,
	MovimentosAvulsosService,
	TarefasRecorrentesService,
	ProjetosRecorrentesService,
	NotificacoesProjetosService,
	TabelaPostagensAnexoService,
	CategoriasFinanceirasService,
	TabelaPostagensConteudoService,
	PropostaProdutosTerceiroService,
	OrcamentoProdutosTerceiroService,
	NotificacoesInteracoesTarefasService,
} from './services';

import { CategoriasPostagemService } from './services/categorias-postagem.service';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
	],
	providers: [
		ApiService,
		EtapasService,
		ClientesService,
		ColaboradoresService,
		ProjetosService,
		SetoresService,
		NiveisUrgenciaService,
		CategoriasPostagemService,
		TarefasService,
		CidadesService,
		EstadosService,
		OrcamentosService,
		OrcamentoPecasService,
		TipoRecorrenciaService,
		TarefasRecorrentesService,
		MessageService,
		OrdemServicoService,
		EtapasRecorrentesService,
		ProjetosRecorrentesService,
		RelatoriosService,
		ExcelService,
		ModalService,
		CaixaService,
		EtapasPadraoService,
		PropostasService,
		DashboardService,
		PermissoesService,
		PromotoresService,
		CustoInternoService,
		FornecedoresService,
		OrdemServicoService,
		TemplatePecaService,
		TiposProjetoService,
		FormaPagamentoService,
		TabelaPostagensService,
		FormaRecebimentoService,
		ProdutosTerceiroService,
		UsuariosClientesService,
		MovimentosAvulsosService,
		NotificacoesProjetosService,
		TabelaPostagensAnexoService,
		CategoriasFinanceirasService,
		TabelaPostagensConteudoService,
		PropostaProdutosTerceiroService,
		OrcamentoProdutosTerceiroService,
		NotificacoesInteracoesTarefasService
	],
	declarations: []
})
export class CoreModule { }

import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class CustoInternoService {
	constructor(private apiService: ApiService) { }

	post(form): Observable<any> {
		let url = '/custo-interno'
		return this.apiService.post(url, form)
	}

	put(id: string, form): Observable<any> {
		let url = `/custo-interno/${id}`
		return this.apiService.put(url, form)
	}

	getAll(status) {
		let url: string = `/custos/${status}`
		return this.apiService.get(url)
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/custo-interno/${id}`
		return this.apiService.get(url)
	}

	delete(id: string): Observable<any> {
		let url = `/custo-interno/${id}`
		return this.apiService.delete(url)
	}

	restoreInativo(id: string): Observable<any> {
		return this.apiService.get(`/restaurar-custo-interno/${id}`)
	}
}
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class EtapasService {
	constructor(private apiService: ApiService) {}

	delete(id:string): Observable<any> {
		let url = `/etapa-deletar/${id}` 
		return this.apiService.delete(url)
	}

	finalizarEtapa(id: string = null) {
		let url = `/etapa-finalizar/${id}`
		return this.apiService.post(url)
	}

	getAll(form) {		
		let url : string = `/etapas`
		return this.apiService.post(url, form)
	}

	lerNotificacoes(form) {
		let url : string = `/notificacoes-etapas/ler/notificacoes`
		return this.apiService.post(url, form)
	}

	getEtapaInicial(id_tarefa: string = null): Observable<any> {
		let url : string = `/etapa-inicial/${id_tarefa}`
		return this.apiService.get(url).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/etapa/${id}`
		return this.apiService.get(url)
	}

	getHorasColaboradoresEtapa(id: string = null): Observable<any> {
		let url: string = `/etapas-horas/${id}`
		return this.apiService.get(url)
	}

	restore(id: string = null): Observable<any> {
		let url: string = `/restaurar-etapa/${id}`
		return this.apiService.get(url)
	}

	post(form) : Observable<any> {
		let url = '/etapa-manual'
    	return this.apiService.post(url, form)
	}

	updateDatas(form) : Observable<any> {
		let url = '/atualizar-datas'
    	return this.apiService.post(url, form)
	}

	put(id: string, form) : Observable<any> {
		let url = `/etapa-atualizar/${id}`
		return this.apiService.post(url, form)
	}

	validarDatasTarefaProjeto(form: any): Observable<any> {
		let url: string = `/validar-datas/${form.id_tarefa}`
		return this.apiService.post(url, form)
	}

	enviarAnexosEtapa(idTarefa: string, idEtapa:string, form: any): Observable<any>{
		let url: string = `/etapa-anexos/${idTarefa}/${idEtapa}`;
		return this.apiService.post(url, form);
    }
}
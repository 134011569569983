import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()

export class CategoriasFinanceirasService {
    constructor(private apiService: ApiService) {}

    post(form) : Observable<any> {
      let url = '/categorias-financeiras'
      return this.apiService.post(url, form)
    }
	
    put(id: string, form) : Observable<any> {
      let url = `/categorias-financeiras/${id}`
      form._method = 'PUT'
      return this.apiService.post(url, form)
    }
    
    getFiltro(form): Observable<any> {
      let url : string = `/categorias-financeiras-filtro`
      return this.apiService.get(url, form)
    }
    
    get(id: string = null): Observable<any> {
      let url : string = id != null ? `/categorias-financeiras/${id}` : `/categorias-financeiras`
      return this.apiService.get(url)
    }

    getSearch(search: string, status: string = '0'): Observable<any> {
      let url : string = `/categorias-financeiras?q=${search}&status=${status}`
      return this.apiService.get(url)
    }

    getInativos(): Observable<any> {
      let url : string = `/categorias-financeiras-inativos`
      return this.apiService.get(url)
    }

    delete(id: number): Observable<any> {
      let url = `/categorias-financeiras/${id}`
      return this.apiService.delete(url)
    }

    restore(id: number): Observable<any> {
      return this.apiService.get(`/restaurar-categoria-financeira/${id}` )
    }
}
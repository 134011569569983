import { Injectable } from '@angular/core'

import { Observable, EMPTY } from 'rxjs'
import { retry, catchError, shareReplay } from 'rxjs/operators'

import { ApiService } from './api.service'

@Injectable()
export class TabelaPostagensService {
    constructor(private apiService: ApiService) {}

	get(id: string = null): Observable<any> {
		let url : string
		url = id != null ? `/tabela-postagens/${id}` : `/tabelas-postagens`;
		return this.apiService.get(url);
	}
	
	getFiltro(id, form) : Observable<any> {
		let url = '/tabelas-postagens-filtro/' + id;
		return this.apiService.post(url, form);
	}

	getAllSelect(form?): Observable<any> {
		let url : string = `/tabelas-postagens-select`
		return this.apiService.get(url, form)
	}

	getAll(form?): Observable<any> {
		let url : string = `/tabelas-postagens`
		return this.apiService.get(url, form)
	}
	getAllDashboard(form?): Observable<any> {
		let url : string = `/tabelas-postagens/dash`
		return this.apiService.get(url, form)
	}

	getAllInativas(form?): Observable<any> {
		let url : string = `/tabelas-postagens-inativas`
		return this.apiService.get(url, form)
	}
	
    post(form) : Observable<any> {
		let url = '/tabela-postagens';
		return this.apiService.post(url, form);
	}
	
    put(id, form) : Observable<any> {
		let url = '/tabela-postagens/' + id;
		return this.apiService.post(url, form);
	}

    delete(id) : Observable<any> {
		let url = '/tabela-postagens/' + id;
		return this.apiService.delete(url);
	}

    inativar(id) : Observable<any> {
		let url = '/inativar/tabela-postagens/' + id;
		return this.apiService.post(url);
	}

    reativar(id) : Observable<any> {
		let url = '/reativar/tabela-postagens/' + id;
		return this.apiService.post(url);
	}
	
    aprovarReprovar(id, form) : Observable<any> {
		let url = '/tabela-postagens/aprovar-reprovar/' + id;
		return this.apiService.post(url, form);
	}

	postInteracaoTabela(idTabela: string, formHorasTabela: any): Observable<any> {
		let url: string = `/horas-tabela/${idTabela}`
		return this.apiService.post(url, formHorasTabela)
    }

	getHorasColaboradoresTabela(idTabela: string = null, idConteudo: string = null): Observable<any> {
		let url: string = `/horas-tabela/${idTabela}/${idConteudo}`
		return this.apiService.get(url)
	}

	getTodasHorasColaboradoresTabela(idTabela: string = null, idConteudo: string = null): Observable<any> {
		let url: string = `/horas-tabela/${idTabela}`
		return this.apiService.get(url)
	}

	deleteHorasTabela(idInteracao: string): Observable<any> {
		let url: string = `/horas-tabela/${idInteracao}`
		return this.apiService.delete(url)
    }
	
	getHorasTabelaProjeto(form: any): Observable<any> {
		let url: string = `/horas-projeto`
		return this.apiService.post(url, form)
	}
	
	getHorasTabelaProjetoEncarregado(form: any): Observable<any> {
		let url: string = `/horas-projeto-encarregado`
		return this.apiService.post(url, form)
	}

	createEtapaTabela(idTabela: string, form: any): Observable<any> {
		let url: string = `/etapas-tabela/${idTabela}`
		return this.apiService.post(url, form)
    }

	editEtapaTabela(idTabela: string, form: any): Observable<any> {
		let url: string = `/etapas-tabela/${idTabela}`
		return this.apiService.put(url, form)
    }
	
	getEtapaTabela(idTabela: string): Observable<any> {
		let url: string = `/etapas-tabela/${idTabela}`
		return this.apiService.get(url);
    }
	
	verifyDeleteEtapa(idTabela: string, idEtapa: any): Observable<any> {
		let url: string = `/etapa-tabela-verify/${idTabela}/${idEtapa}`;
		return this.apiService.get(url)
	}
	
	deleteEtapa(idTabela: string, idEtapa: any): Observable<any> {
		let url: string = `/etapa-tabela-verify/${idTabela}/${idEtapa}`;
		return this.apiService.delete(url)
	}

	alteraTodasEtapas(idTabela: string, form: any): Observable<any> {
		let url: string = `/alterar-etapas-tabela/${idTabela}`
		return this.apiService.put(url, form)
	}

}
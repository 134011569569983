import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class DashboardService {
	constructor(private apiService: ApiService) {}

	getDashboardVencimento(filtro): Observable<any> {
        return this.apiService.get('/dashboard-vencimentos', filtro)
    }
}
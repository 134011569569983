import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class FormaRecebimentoService {
    constructor(
    private apiService: ApiService
    ) {}
    
    get(id: string): Observable<any> {
        let url : string = `/formas-recebimento/${id}`
        return this.apiService.get(url)
    }
    
    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/formas-recebimento`
        return this.apiService.get(url, dadosFiltro)
    }

    getACombinar(): Observable<any> {
        let url : string = `/verifica-formas-recebimento-a-combinar`
        return this.apiService.get(url)
    }

    changeACombinar(param): Observable<any> {
        let url : string = param == 1 ? `/ativa-formas-recebimento-a-combinar` : `/desativa-formas-recebimento-a-combinar`
        return this.apiService.get(url)
    }
    
	post(form) : Observable<any> {
        return this.apiService.post('/forma-recebimento', form);
    }
    
    put(id: string, form) : Observable<any> {
        return this.apiService.post('/forma-recebimento/' + id, form);
    }
    
    delete(id: any) {
        return this.apiService.post('/forma-recebimento/delete/' + id)
    }

    restore(id: any): Observable<any> {
        return this.apiService.post(`/forma-recebimento/restore/` + id )
    }
    
    uploadImage(id: string, request) : Observable<any> {
        return this.apiService.post('/formas-recebimento/upload/'+id, request)
    }
}
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class MovimentosAvulsosService {
    constructor(private apiService: ApiService) {}

    post(form) : Observable<any> {
		  let url = '/movimentos-avulsos'
    	return this.apiService.post(url, form)
    }

    put(id: string, form) : Observable<any> {
      let url = `/movimentos-avulsos/${id}`
      form._method = 'PUT'
      return this.apiService.post(url, form)
    }
    
    get(id: string = null): Observable<any> {
      let url : string
      url = id != null ? `/movimentos-avulsos/${id}` : `/movimentos-avulsos`
      return this.apiService.get(url)
    }
    
    getAll(filtro?): Observable<any> {
      return this.apiService.get(`/movimentos-avulsos`, filtro)
    }
    
    getInativos(): Observable<any> {
      let url : string
      url = `/movimentos-avulsos-inativos`
      return this.apiService.get(url)
    }
    
    restore(id: string): Observable<any> {
      return this.apiService.get(`/restaurar-movimentos-avulsos/${id}` )
    }

    delete(id:string): Observable<any> {
      let url = `/movimentos-avulsos/${id}`
      return this.apiService.delete(url)
    }
}
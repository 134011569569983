import { AfterViewInit, Component, OnInit } from "@angular/core";
import { PermissoesService } from "../../core/services";
import * as $ from "jquery";

declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	class: string;
	children?: Array<object>;
}
export const ROUTES: RouteInfo[] = [
	{ path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
	{ path: '/clientes', title: 'Clientes', icon: 'contacts', class: '' },
	{ path: '/fornecedores', title: 'Fornecedores', icon: 'people_outline', class: '' },
	{ path: '/promotores', title: 'Promotores', icon: 'connect_without_contact', class: '' },
	{ path: '/colaboradores', title: 'Colaboradores', icon: 'person', class: '' },
	{ path: '/setores', title: 'Setores', icon: 'group', class: '' },
	{ path: '/niveis-urgencia', title: 'Niveis Urgência', icon: 'group', class: '' },
	{ path: "/categorias-postagem", title: "Categ. Postagem", icon: "group", class: "" },
	{ path: "/etapas-padrao", title: "Etapas Padrão", icon: "menu", class: "" },
	{ path: '/projetos', title: 'Projetos', icon: 'work_outline', class: '' },
	{ path: '/listagem-tabelas', title: 'Tabelas', icon: 'crop_portrait', class: '' },
	{ path: '/listagem-tabela-conteudo', title: 'Postagens', icon: 'pages', class: '' },
	{ path: '/tipos-projeto', title: 'Tipos de Projeto', icon: 'class', class: '' },
	{ path: '/template-pecas', title: 'Temp. de Peças', icon: 'build_circle', class: '' },
	{ path: '/custos-interno', title: 'Custos Interno', icon: 'settings_accessibility', class: '' },
	{ path: '/produtos-terceiro', title: 'Prod. Terceiro', icon: 'shopping_bag', class: '' },
	{ path: '/orcamentos', title: 'Orçamentos', icon: 'payment', class: '' },
	{ path: '/propostas', title: 'Propostas', icon: 'shopping_basket', class: '' },
	{ path: '/financeiro', title: 'Financeiro',icon: 'attach_money', class: 'dropdown', children: [
		{ path: '/caixa', title: 'Caixa', icon: 'attach_money', class: ''}, 
		{ path: '/movimentos-avulsos', title: 'Mov. Avulsos', icon: 'attach_money', class: ''}, 
		{ path: '/categorias-financeiras', title: 'Cat. Financeiras', icon: 'attach_money', class: ''},
		{ path: '/formas-pagamento', title: 'Formas Pagto.', icon: 'paid', class: ''},
		{ path: '/formas-recebimento', title: 'Formas Rece.', icon: 'paid', class: ''},
		{ path: '/relatorio-entradas', title: 'Rel. Entradas', icon: 'assignment', class: '' },
		{ path: '/relatorio-saidas', title: 'Rel. Saídas', icon: 'assignment', class: '' },
		{ path: '/relatorio-vendas', title: 'Rel. Vendas', icon: 'assignment', class: '' }
	]},
	{ path: '/relatorios', title: 'Relatórios',  icon: 'assignment', class: 'dropdown', children: [
		{ path: '/relatorios-clientes', title: 'Clientes',  icon: 'assignment', class: '' },
		{ path: '/relatorios-colaboradores', title: 'Colaboradores',  icon: 'assignment', class: '' },
		{ path: '/relatorios-setores', title: 'Setores',  icon: 'assignment', class: '' }
	]
}]
export const ROUTES_CLIENTES: RouteInfo[] = [
	{ path: "/dashboard", title: "Dashboard", icon: "dashboard", class: "" },
	{ path: "/clientes", title: "Clientes", icon: "contacts", class: "" },
	{
		path: "/fornecedores",
		title: "Fornecedores",
		icon: "people_outline",
		class: "",
	},
	{
		path: "/promotores",
		title: "Promotores",
		icon: "connect_without_contact",
		class: "",
	},
	{
		path: "/colaboradores",
		title: "Colaboradores",
		icon: "person",
		class: "",
	},
	{ path: "/setores", title: "Setores", icon: "group", class: "" },
	{ path: "/niveis-urgencia", title: "Niveis Urgência", icon: "group", class: "" },
	{ path: "/categorias-postagem", title: "Categ. Postagem", icon: "group", class: "" },
	{ path: "/projetos", title: "Projetos", icon: "work_outline", class: "" },
	{
		path: "/relatorios",
		title: "Relatórios",
		icon: "assignment",
		class: "dropdown",
		children: [
			{
				path: "/relatorios-clientes",
				title: "Clientes",
				icon: "assignment",
				class: "",
			},
			{
				path: "/relatorios-colaboradores",
				title: "Colaboradores",
				icon: "assignment",
				class: "",
			},
			{
				path: "/relatorios-setores",
				title: "Setores",
				icon: "assignment",
				class: "",
			},
		],
	},
];

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, AfterViewInit {
	menuItems: any[];
	usuario = JSON.parse(localStorage.getItem("usuario"));

	public logoPath: string = "/assets/img/logo/upplay-logo.png";

	constructor(private PermissoesService: PermissoesService) {}

	ngOnInit() {
		this.menuItems = ROUTES;
	}

	async ngAfterViewInit() {
		this.verificarPermissoes();
	}

	isMobileMenu() {
		if ($(window).width() > 991) {
			return false;
		}
		return true;
	}

	async verificarPermissoes() {
		const { data: permissoesCadastrosUsuario } =
			await this.PermissoesService.buscarApenasPermissoesCadastros(
				this.usuario.id
			).toPromise();

		if (this.usuario.colaborador == null) {
			var rotasPermitidas = ROUTES_CLIENTES.filter((route) => {
				if (
					this.usuario.admin ||
					route.path.split("/")[1] === "dashboard"
				)
					return route;

				let rotaPermitida = permissoesCadastrosUsuario.find(
					(rotaCadastrada) =>
						rotaCadastrada.slug === route.path.split("/")[1] &&
						rotaCadastrada.ler === true
				);
				if (rotaPermitida) return route;

				if (route.children) {
					let childrens = route.children.filter((children) => {
						let rotaPermitida = permissoesCadastrosUsuario.find(
							(rotaCadastrada) =>
								rotaCadastrada.slug ===
									children["path"].split("/")[1] &&
								rotaCadastrada.ler === true
						);
						if (rotaPermitida) return children;
					});

					if (childrens.length) {
						route.children = childrens;
						return route;
					}
				}
			});
		} else {
			var rotasPermitidas = ROUTES.filter((route) => {
				if (
					this.usuario.admin ||
					route.path.split("/")[1] === "dashboard"
				)
					return route;

				let rotaPermitida = permissoesCadastrosUsuario.find(
					(rotaCadastrada) =>
						rotaCadastrada.slug === route.path.split("/")[1] &&
						rotaCadastrada.ler === true
				);
				if (rotaPermitida) return route;

				if (route.children) {
					let childrens = route.children.filter((children) => {
						let rotaPermitida = permissoesCadastrosUsuario.find(
							(rotaCadastrada) =>
								rotaCadastrada.slug ===
									children["path"].split("/")[1] &&
								rotaCadastrada.ler === true
						);
						if (rotaPermitida) return children;
					});

					if (childrens.length) {
						route.children = childrens;
						return route;
					}
				}
			});
		}

		this.menuItems = rotasPermitidas;
	}
}

import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class OrcamentosService {
	constructor(private apiService: ApiService) { }

	post(form): Observable<any> {
		let url = '/orcamento'
		return this.apiService.post(url, form)
	}

	put(id: string, form): Observable<any> {
		let url = `/orcamento/${id}`
		return this.apiService.put(url, form)
	}

	pdf(id: string, formato: string): Observable<any> {
        return this.apiService.get(`/pdf-orcamento/${id}/${formato}`);
	}

	delete(id) {
		let url: string = `/orcamento/${id}`
		return this.apiService.delete(url)
	}

	lancar(id: string, form): Observable<any> {
		let url = `/orcamento-lancar/${id}`
		return this.apiService.put(url, form)
	}

	getAll(filtro?): Observable<any> {
        return this.apiService.get('/orcamentos', filtro)
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/orcamento/${id}`
		return this.apiService.get(url)
	}
}
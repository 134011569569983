import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class PropostasService {
	constructor(private apiService: ApiService) { }

	//------------------------------------
	//---------------------------Propostas
	//------------------------------------
	post(form): Observable<any> {
		let url = '/proposta';
		return this.apiService.post(url, form);
	}

	aprovarProposta(id): Observable<any> {
		let url = `/proposta-aprovar-os/${id}`;
		return this.apiService.post(url);
	}

	put(id: string, form): Observable<any> {
		let url = `/proposta/${id}`;
		return this.apiService.put(url, form);
	}

	getAll(filtro?): Observable<any> {
        return this.apiService.get('/propostas', filtro);
	}

	pdf(id: string, formato: string): Observable<any> {
        return this.apiService.get(`/pdf-proposta/${id}/${formato}`);
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/proposta/${id}`;
		return this.apiService.get(url);
	}
	
	delete(id): Observable<any> {
		let url = `/proposta/${id}`;
		return this.apiService.delete(url);
	}


	//------------------------------------
	//-----------------------Peças Orçadas
	//------------------------------------
	postItem(form): Observable<any> {
		let url = '/proposta-item';
		return this.apiService.post(url, form);
	}

	putItem(id: string, form): Observable<any> {
		let url = `/proposta-item/${id}`;
		return this.apiService.put(url, form);
	}

	deleteItem(id): Observable<any> {
		let url = `/proposta-item/${id}`;
		return this.apiService.delete(url);
	}

	getAllItem(filtro?): Observable<any> {
        return this.apiService.get('/proposta-itens', filtro);
	}

	getByIdItem(id: string = null): Observable<any> {
		let url: string = `/proposta-item/${id}`;
		return this.apiService.get(url);
	}

	//------------------------------------
	//-----------------------Custo Interno
	//------------------------------------
	postCustoInterno(form): Observable<any> {
		let url = '/proposta-custo';
		return this.apiService.post(url, form);
	}

	putCustoInterno(id: string, form): Observable<any> {
		let url = `/proposta-custo/${id}`;
		return this.apiService.put(url, form);
	}

	getAllCustoInterno(filtro?): Observable<any> {
        return this.apiService.get('/proposta-custos', filtro);
	}

	getByIdCustoInterno(id: string = null): Observable<any> {
		let url: string = `/proposta-custo/${id}`;
		return this.apiService.get(url);
	}
	
	deleteCustoInterno(id): Observable<any> {
		let url = `/proposta-custo/${id}`;
		return this.apiService.delete(url);
	}

}
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class NotificacoesProjetosService {
	constructor(private apiService: ApiService) {}

	getTodasFiltro(filtro): Observable<any> {
        return this.apiService.get('/notificacoes-projetos/todas/filtro', filtro)
	}

	lerNotificacoes(form: any) : Observable<any> {
		let url: string = `/notificacoes-projetos/ler/notificacoes`
		return this.apiService.post(url, form)
	}

	lerNotificacao(form: any) : Observable<any> {
		let url: string = `notificacoes-projetos/ler/notificacao/id`
		return this.apiService.post(url, form)
	}

}
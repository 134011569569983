import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule, MatIconModule } from '@angular/material';

import { CoreModule } from 'app/core';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoadingComponent } from './loading/loading.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  imports: [
	  CoreModule,
    CommonModule,
    RouterModule,
  	MatIconModule,
	  MatProgressSpinnerModule
  ],
  declarations: [
    FooterComponent,
	  NavbarComponent,
	  LoadingComponent,
    SidebarComponent
  ],
  exports: [
    FooterComponent,
	NavbarComponent,
	LoadingComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }

import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class TemplatePecaService {
	constructor(private apiService: ApiService) { }

	/**
	 * Requisições referentes as peças
	 */

	post(form): Observable<any> {
		let url = '/peca'
		return this.apiService.post(url, form)
	}

	put(id: string, form): Observable<any> {
		let url = `/peca/${id}`
		return this.apiService.put(url, form)
	}

	getAll(form) {
		let url: string = `/pecas-todas`
		return this.apiService.get(url, form)
	}

	getPecaId(id: string = null): Observable<any> {
		let url: string = `/peca/${id}`
		return this.apiService.get(url)
	}

	delete(id: string): Observable<any> {
		let url = `/peca/${id}`
		return this.apiService.delete(url)
	}

	restoreInativo(id: string): Observable<any> {
		return this.apiService.get(`/restaurar-peca/${id}`)
	}

	/**
	 * Requisições referentes aos campos das peças
	 */

	getCampoId(id: string = null): Observable<any> {
		let url: string = `/peca-campo/${id}`
		return this.apiService.get(url).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}

	getAllCampos(form) {
		let url: string = `/pecas-campos`
		return this.apiService.post(url, form)
	}

	postCampo(formTipoProjeto): Observable<any> {
		let url = '/peca-campo'
		return this.apiService.post(url, formTipoProjeto)
	}

	putCampo(id: string, form): Observable<any> {
		let url = `/peca-campo/${id}`
		return this.apiService.put(url, form)
	}

	deleteCampo(id: string): Observable<any> {
		let url = `/peca-campo/${id}`
		return this.apiService.delete(url)
	}

	restoreCampoInativo(id: string): Observable<any> {
		return this.apiService.get(`/restaurar-peca-campo/${id}`)
	}

	/**
	 * Requisições referentes as etapas das peças
	 */

	getEtapaId(id: string = null): Observable<any> {
		let url: string = `/peca-etapa/${id}`
		return this.apiService.get(url).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}

	getEtapaInicial(id_peca: string = null): Observable<any> {
		let url: string = `/peca-etapa-inicial/${id_peca}`
		return this.apiService.get(url).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}

	getAllEtapas(form) {
		let url: string = `/pecas-etapas`
		return this.apiService.post(url, form)
	}

	postEtapa(formTipoProjeto): Observable<any> {
		let url = '/peca-etapa'
		return this.apiService.post(url, formTipoProjeto)
	}

	putEtapa(id: string, form): Observable<any> {
		let url = `/peca-etapa/${id}`
		return this.apiService.put(url, form)
	}

	deleteEtapa(id: string): Observable<any> {
		let url = `/peca-etapa/${id}`
		return this.apiService.delete(url)
	}

	restoreEtapaInativo(id: string): Observable<any> {
		return this.apiService.get(`/restaurar-peca-etapa/${id}`)
	}
}
import { Injectable } from '@angular/core'

import { Observable, EMPTY } from 'rxjs'
import { retry, catchError, shareReplay } from 'rxjs/operators'

import { ApiService } from './api.service'

@Injectable()
export class TabelaPostagensAnexoService {
    constructor(private apiService: ApiService) {}

	get(id: string = null): Observable<any> {
		let url : string
		url = id != null ? `/tabela-anexo/${id}` : `/tabelas-anexos`;
		return this.apiService.get(url);
	}
	
    post(form) : Observable<any> {
		let url = '/tabela-anexo';
		return this.apiService.post(url, form);
	}

    postAnexoTabela(form) : Observable<any> {
		let url = '/tabela-anexo-tabela';
		return this.apiService.post(url, form);
	}
	
    put(id, form) : Observable<any> {
		let url = '/tabela-anexo/' + id;
		return this.apiService.post(url, form);
	}

	delete(id): Observable<any> {
		let url = `/deletar/tabela-anexo/${id}`;
		return this.apiService.post(url);
	}

	inativar(id): Observable<any> {
		let url = `/inativar/tabela-anexo/${id}`;
		return this.apiService.post(url);
	}

	upload(form): Observable<any> {
		let url = `/tabela-anexo-upload`;
		return this.apiService.post(url, form);
	}

	deleteObject(form): Observable<any> {
		let url = `/tabela-anexo-deleteObject`;
		return this.apiService.post(url, form);
	}

	addDescription(form): Observable<any> {
		let url = `/tabela-anexo-addDescription`;
		return this.apiService.post(url, form);
	}

	download(form): Observable<any> {
		let url = `/tabela-anexo-download`;
		return this.apiService.get(url, form);
	}

	getUrl(form): Observable<any> {
		let url = `/tabela-anexo-getUrl`;
		return this.apiService.post(url, form);
	}

	atualizarOrdemImagens(form): Observable<any> {
		let url = `/atualizar-ordem-imagens `;
		return this.apiService.post(url, form);
	}


}
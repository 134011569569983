import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class TarefasService {
    constructor(private apiService: ApiService) {}

    post(form: any) : Observable<any> {
      let url: string = `/tarefas`
      return this.apiService.post(url, form)
    }

    put(id: string, form: any) : Observable<any> {
      let url: string = `/tarefas/${id}`
      return this.apiService.post(url, form)
    }

    updateDataProjeto(form: any) : Observable<any> {
      let url: string = `/atualizar-datas-projeto`
      return this.apiService.post(url, form)
    }
    
    getAll(form: any) : Observable<any> {
      let url: string = `/todas-tarefas`
      return this.apiService.get(url, form)
    }
    
    get(id: string = null): Observable<any> {
      let url : string = id != null ? `/tarefas/${id}` : `/tarefas`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getTarefasProjeto(idProjeto: string, status: string = "0"): Observable<any> {
      	return this.apiService.get(`/tarefas-projeto/${idProjeto}/${status}`).pipe(
          retry(5),
          catchError(() => {
            return EMPTY
          }),
          shareReplay()
        )
    }

    getTarefasColaborador(form): Observable<any> {
      return this.apiService.get(`/tarefas-colaborador`, form).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getTarefasSetor(form): Observable<any> {
      return this.apiService.get(`/tarefas-setor`, form).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getTarefasCliente(form): Observable<any> {
      return this.apiService.get(`/tarefas-cliente`, form).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getTarefasUsuarioCliente(form): Observable<any> {
      return this.apiService.get(`/tarefas-usuario-cliente`, form).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getTarefasComUsuariosClientesDashboard(id_usuario, status: string) {
      let filtros = new HttpParams().set('status', status).set('id_usuario', id_usuario)
      return this.apiService.get(`/tarefas-usuarios-clientes-dashboard`, filtros).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getTarefasFiltroBusca(form) {
      let url : string = `/tarefas-filtro-busca`
      return this.apiService.post(url, form).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
	}
	
	getTarefasDashboard(filtro): Observable<any> {
        return this.apiService.get('/tarefas-dashboard', filtro)
    }
	
	getTarefasProjetosDashboard(filtro): Observable<any> {
        return this.apiService.get('/tarefas-projetos-dashboard', filtro)
    }

    validarDatasTarefaProjeto(form: any): Observable<any> {
		  let url: string = `/validar-datas-tarefas-projeto/${form.id_projeto}`
      	return this.apiService.post(url, form)
    }

    postInteracaoTarefa(idTarefa: string, formHorasTarefa: any): Observable<any> {
		let url: string = `/horas-tarefa/${idTarefa}`
		return this.apiService.post(url, formHorasTarefa)
    }

    alterarStatusEmAprovacao(form: any): Observable<any> {
		let url: string = `/alterar-status-em-aprovacao`
		return this.apiService.post(url, form)
    }
    
    deleteHorasTarefa(idInteracao: string): Observable<any> {
		let url: string = `/horas-tarefa/${idInteracao}`
		return this.apiService.delete(url)
    }
    
    deleteMensagemUsuarioCliente(idInteracao: string): Observable<any> {
		let url: string = `/tarefa-mensagem-usuario-cliente/${idInteracao}`
		return this.apiService.delete(url)
    }

    encerrarTarefa(idtarefa: string): Observable<any> {
      	return this.apiService.get(`/encerrar-tarefa/${idtarefa}` )
    }

    getTarefasProjetoOrcamento(id_projeto: number): Observable<any> {
      	return this.apiService.get(`/tarefas-prjeto-orcamento/${id_projeto}` )
    }

    restore(idTarefa: string): Observable<any> {
      	return this.apiService.get(`/restaurar-tarefa/${idTarefa}` )
    }
    
    restoreInativo(idTarefa: string): Observable<any> {
      	return this.apiService.get(`/restaurar-tarefa-inativa/${idTarefa}` )
    }

    delete(id:string): Observable<any> {
      let url = `/tarefas/${id}` 
      return this.apiService.delete(url)
    }

    enviarAnexos(idTarefa: string, form: any): Observable<any>{
		let url: string = `/tarefas-anexos/${idTarefa}`
		return this.apiService.post(url, form)
    }

    deleteAnexos(idAnexo: string): Observable<any>{
		let url: string = `/tarefas-anexos/${idAnexo}`
		return this.apiService.delete(url)
	}
	
	// Respostas da Peça/Tarefa
	getRespostas(id_tarefa): Observable<any> { 
		return this.apiService.get(`/respostas-peca-tarefa/${id_tarefa}`).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}
}
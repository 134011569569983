import { Injectable } from '@angular/core'
import {  BehaviorSubject, Subject } from 'rxjs'

@Injectable()

export class MessageService {
    
    private messageSourceOne = new Subject<any>()
    private messageSourceTwo = new Subject<any>()
    private messageSourceThree = new Subject<any>()
    private messageSourceFour = new Subject<any>()
    private messageSourceFive = new Subject<any>()
    private messageSourceSix = new Subject<any>()
    private messageSourceSeven = new Subject<any>()
    private messagePermissoesUsuario = new Subject<any>()
    private messageEditarTarefa = new Subject<any>()
    private messageDadosToAdicionarFormasCadastroMovimentos = new Subject<any>()
    private messageFormaToCallerComponent = new Subject<any>()
    private messageUpdateFormaToCallerComponent = new Subject<any>()
    private messageProjetoToPropostaComponent = new Subject<any>()
    private messageDashboardToReloader = new Subject<any>()
    private messageDashboardToListaPecas = new Subject<any>()

    constructor() { }

    send_messageOne(message){
        this.messageSourceOne.next(message)
    }

    clear_messageOne(){
        this.messageSourceOne.next()
    }
    
    get_messageOne(){
        return this.messageSourceOne.asObservable()
    }

    send_messageTwo(message){
        this.messageSourceTwo.next(message)
    }

    clear_messageTwo(){
        this.messageSourceTwo.next()
    }
    
    get_messageTwo(){
        return this.messageSourceTwo.asObservable()
    }

    send_messageThree(message){
        this.messageSourceThree.next(message)
    }

    clear_messageThree(){
        this.messageSourceThree.next()
    }
    
    get_messageThree(){
        return this.messageSourceThree.asObservable()
    }
    
    send_messageFour(message){
        this.messageSourceFour.next(message)
    }

    clear_messageFour(){
        this.messageSourceFour.next()
    }
    
    get_messageFour(){
        return this.messageSourceFour.asObservable()
    }
    
    send_messageFive(message){
        this.messageSourceFive.next(message)
    }

    clear_messageFive(){
        this.messageSourceFive.next()
    }
    
    get_messageFive(){
        return this.messageSourceFive.asObservable()
    }
    
    send_messageSix(message){
        this.messageSourceSix.next(message)
    }

    clear_messageSix(){
        this.messageSourceSix.next()
    }
    
    get_messageSix(){
        return this.messageSourceSix.asObservable()
    }
    
    send_messageSeven(message){
        this.messageSourceSeven.next(message)
    }

    clear_messageSeven(){
        this.messageSourceSeven.next()
    }
    
    get_messageSeven(){
        return this.messageSourceSeven.asObservable()
    }

    sendPermissoesUsuarioToCadastrar(message){
        this.messagePermissoesUsuario.next(message)
    }
    
    getPermissoesUsuarioToCadastrar(){
        return this.messagePermissoesUsuario.asObservable()
    }

    sendEditarTarefa(message){
        this.messageEditarTarefa.next(message)
    }
    
    getEditarTarefa(){
        return this.messageEditarTarefa.asObservable()
    }

    sendDadosToAdicionarFormasCadastroMovimentos(message){
        this.messageDadosToAdicionarFormasCadastroMovimentos.next(message)
    }
    
    getDadosToAdicionarFormasCadastroMovimentos(){
        return this.messageDadosToAdicionarFormasCadastroMovimentos.asObservable()
    }

    sendFormaToCallerComponent(message){
        this.messageFormaToCallerComponent.next(message)
    }
    
    getFormaToCallerComponent(){
        return this.messageFormaToCallerComponent.asObservable()
    }

    sendUpdateFormaToCallerComponent(message){
        this.messageUpdateFormaToCallerComponent.next(message)
    }
    
    getUpdateFormaToCallerComponent(){
        return this.messageUpdateFormaToCallerComponent.asObservable()
    }

    sendProjetoToPropostaComponent(message){
        this.messageProjetoToPropostaComponent.next(message)
    }
    
    getProjetoToPropostaComponent(){
        return this.messageProjetoToPropostaComponent.asObservable()
    }

    sendDashboardToReloader(message){
        this.messageDashboardToReloader.next(message)
    }
    
    getDashboardToReloader(){
        return this.messageDashboardToReloader.asObservable()
    }

    sendDashboardToListaPecas(message){
        this.messageDashboardToListaPecas.next(message)
    }
    
    getDashboardToListaPecas(){
        return this.messageDashboardToListaPecas.asObservable()
    }
}
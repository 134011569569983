import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoginService {
    constructor(private httpClient: HttpClient) {

    }

    login(formLogin): Observable<any> {
		return this.httpClient.post('/auth/login', formLogin)
    }

}
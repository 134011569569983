import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

import DadosMovimento from '../../interfaces/dados-movimento.interface'
import DadosMovimentoProjeto from '../../interfaces/dados-movimento-projeto.interface'

@Injectable()
export class CaixaService {
    constructor(private apiService: ApiService) {
        
    }

    cadastrarMovimentoAvulso(dadosMovimento: DadosMovimento): Observable<any> {
        return this.apiService.post('/cadastrar-movimento', dadosMovimento)
    }

    editarMovimentoAvulso(dadosMovimento: DadosMovimento): Observable<any> {
        return this.apiService.post('/editar-movimento', dadosMovimento)
    }

    cadastrarMovimentosProjeto(dadosMovimentoProjeto: DadosMovimentoProjeto): Observable<any> {
        return this.apiService.post('/cadastrar-movimento-projeto', dadosMovimentoProjeto)
    }
    
    // editarMovimentosProjeto(dadosMovimentoProjeto: DadosMovimentoProjeto): Observable<any> {
    //     return this.apiService.post('/editar-movimento-projeto', dadosMovimentoProjeto)
    // }

    cadastrarMovimentosProposta(dadosMovimentoProposta: any): Observable<any> {
        return this.apiService.post('/cadastrar-movimento-proposta', dadosMovimentoProposta)
    }

    getContasReceber(filtro): Observable<any> {
        return this.apiService.get('/contas-receber', filtro)
    }
    
    getContasPagar(filtro): Observable<any> {
        return this.apiService.get('/contas-pagar', filtro)
    }

    getHistorico(filtro?): Observable<any> {
        return this.apiService.get('/caixa-historico', filtro)
	}

    getRelatorioEntradas(filtro?): Observable<any> {
        return this.apiService.get('/relatorio-entradas', filtro)
	}

    getRelatorioSaidas(filtro?): Observable<any> {
        return this.apiService.get('/relatorio-saidas', filtro)
	}

    getRelatorioVendas(filtro?): Observable<any> {
        return this.apiService.get('/relatorio-vendas', filtro)
	}

    getHistoricoTotal(filtro?): Observable<any> {
        return this.apiService.get('/caixa-historico/totais', filtro)
	}
	
	getParcelasMovimentosAvulsos(id): Observable<any> {
		return this.apiService.get(`/parcelas-movimentos-avulsos/${id}`)
	}

    postMovimentosPagos(movimentosPagos): Observable<any> {
        return this.apiService.post('/movimentos-pagos', movimentosPagos)
    }

    postMovimentosEmitidos(movimentosEmitidos): Observable<any> {
        return this.apiService.post('/atualizar/cobranca-emitida', movimentosEmitidos)
    }
	
	deleteFaturamento(id_projeto:string): Observable<any> {
		let url = `/delete-faturamento/${id_projeto}`
		return this.apiService.delete(url)
	}
	
	deletePreFaturamento(id_proposta:string): Observable<any> {
		let url = `/delete-pre-faturamento/${id_proposta}`
		return this.apiService.delete(url)
	}
	
	deleteOrdemServico(id_projeto:string): Observable<any> {
		let url = `/delete-ordem-servico/${id_projeto}`
		return this.apiService.delete(url)
	}
}
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class FormaPagamentoService {
    constructor(
    	private apiService: ApiService
    ) {}
    
    get(id): Observable<any> {
        let url : string = `/formas-pagamento/${id}`;
        return this.apiService.get(url);
    }
    
    getAll(dadosFiltro?): Observable<any> {
        let url : string = `/formas-pagamento`;
        return this.apiService.get(url, dadosFiltro);
    }
    
    post(form) : Observable<any> {
        return this.apiService.post('/forma-pagamento', form);
    }
    
    put(id: string, form) : Observable<any> {
        return this.apiService.post('/forma-pagamento/' + id, form);
    }
    
    delete(id) {
        return this.apiService.post('/forma-pagamento/delete/' + id);
    }

    restore(id): Observable<any> {
        return this.apiService.post(`/forma-pagamento/restore/` + id);
    }
    
    uploadImage(id, request) : Observable<any> {
        return this.apiService.post('/formas-pagamento/upload/'+id, request);
    }
}
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { Router } from '@angular/router'

// import { JwtService } from './jwt.service';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError , shareReplay, map, retry } from 'rxjs/operators';
import { Utils } from '../../../assets/ts/utils';

@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient,
        private router: Router
        // private jwtService: JwtService
    ) { }

    private formatErrors(error: any) {
        return new ErrorObservable(error.error);
    }

    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.get(`${environment.apiUrl}${path}`, { params, headers: Utils.buscarTokenAutenticacao() }).pipe(map(data => { return data })).pipe(
            retry(5),
            catchError((error) => {
                if ( error.status === 401 ) {
                    Utils.logout(this.router)
                    Utils.showNotification('danger', error.error.message)
                }
                return EMPTY
            }),
            shareReplay()
        );
    }

    put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}${path}`, body, { headers: Utils.buscarTokenAutenticacao() }
        ).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: Object = {}): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}${path}`, body, { headers: Utils.buscarTokenAutenticacao() }
        ).pipe(catchError(this.formatErrors));
    }

    delete(path): Observable<any> {
        return this.http.delete(
            `${environment.apiUrl}${path}`, { headers: Utils.buscarTokenAutenticacao() }
        ).pipe(catchError(this.formatErrors));
    }
}

import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class EtapasRecorrentesService {
	constructor(private apiService: ApiService) {}

	delete(id:string): Observable<any> {
		let url = `/etapa-recorrente-deletar/${id}` 
		return this.apiService.delete(url)
	}

	finalizarEtapa(id: string = null) {
		let url = `/etapa-recorrente-finalizar/${id}`
		return this.apiService.post(url)
	}

	getAll(form) {
		let url : string = `/etapas-recorrentes`
		return this.apiService.post(url, form)
	}

	getEtapaInicial(id_tarefa: string = null): Observable<any> {
		let url : string = `/etapa-recorrente-inicial/${id_tarefa}`
		return this.apiService.get(url).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/etapa-recorrente/${id}`
		return this.apiService.get(url)
	}

	post(form) : Observable<any> {
		let url = '/etapa-recorrente-manual'
    	return this.apiService.post(url, form)
	}

	put(id: string, form) : Observable<any> {
		let url = `/etapa-recorrente-atualizar/${id}`
		return this.apiService.post(url, form)
	}
}
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class ColaboradoresService {
    constructor(private apiService: ApiService) {}

    post(form) : Observable<any> {
      let url = '/colaboradores'
      return this.apiService.post(url, form)
    }

    put(id: string, form) : Observable<any> {
      let url = `/colaboradores/${id}`
      return this.apiService.post(url, form)
    }
    
    get(id: string = null): Observable<any> {
      let url : string
      url = id != null ? `/colaboradores/${id}` : `/colaboradores`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getAllFilter(form) : Observable<any> {
      let url = '/colaboradores-filtro'
      return this.apiService.get(url, form)
    }

    getInativos(): Observable<any> {
      let url : string =  `/colaboradores-inativos`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    delete(id:string): Observable<any> {
      let url = `/colaboradores/${id}`
      return this.apiService.delete(url)
    }

    restoreInativo(id:string){
      return this.apiService.get(`/restaurar-colaborador/${id}` )
    }
}
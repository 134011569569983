import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class EtapasPadraoService {
    constructor(private apiService: ApiService) {}

    post(form) : Observable<any> {
		  let url = '/padrao-etapa'
    	return this.apiService.post(url, form)
    }

    put(id: string, form) : Observable<any> {
      let url = `/padrao-etapa/${id}`
      return this.apiService.put(url, form)
    }
    
    get(id: string): Observable<any> {
      let url : string
      url = `/padrao-etapa/${id}`
      return this.apiService.get(url)
    }
    getAll(): Observable<any> {
      let url : string
      url = `/padrao-etapa`
      return this.apiService.get(url)
    }
    

    getInativos(): Observable<any> {
      let url : string
      url = `/padrao-etapa-inativos`
      return this.apiService.get(url)
    }
    
    restore(id: string): Observable<any> {
      return this.apiService.post(`/padrao-etapa/restore/${id}` )
    }

    delete(id:string): Observable<any> {
      let url = `/padrao-etapa/${id}`
      return this.apiService.delete(url)
    }


    getEtapas(id:String): Observable<any> {
      let url : string
      url = `/get-etapas-padrao/${id}`
      return this.apiService.get(url)
    }
    
    deleteEtapa(idEtapa: any): Observable<any> {
      let url: string = `/etapa-padrao/${idEtapa}`;
      return this.apiService.delete(url)
    }
    
}
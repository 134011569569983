import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'

@Injectable()

export class TipoRecorrenciaService {
    constructor(private apiService: ApiService) {}
    
    get(id: string = null): Observable<any> {
      let url : string
      url = id != null ? `/tipos-recorrencia/${id}` : `/tipos-recorrencia`
      return this.apiService.get(url)
    }
}
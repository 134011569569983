import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class PromotoresService {
    constructor(private apiService: ApiService) {

    }
  
    get(id: string = null): Observable<any> {
        let url : string = id != null ? `/promotores/${id}` : `/promotores`
        return this.apiService.get(url)
    }
    
    post(form) : Observable<any> {
        let url = '/promotores'
        return this.apiService.post(url, form)
    }
      
    put(id: string, form) : Observable<any> {
        let url = `/promotores/${id}`
        form._method = 'PUT'
        return this.apiService.post(url, form)
    }

    getSearch(search: string, status: string = '0'): Observable<any> {
        let url : string = `/promotores?q=${search}&status=${status}`
        return this.apiService.get(url)
    }

    getInativos(): Observable<any> {
        let url : string = `/promotores-inativos`
        return this.apiService.get(url)
    }

    delete(id:string): Observable<any> {
        let url = `/promotores/${id}`
        return this.apiService.delete(url)
    }

    restoreInativo(id: string): Observable<any> {
        return this.apiService.get(`/restaurar-promotor/${id}` )
    }
}
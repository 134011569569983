import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class UsuariosClientesService {
    constructor(private apiService: ApiService) {}

    post(form) : Observable<any> {
		  let url = '/usuarios-clientes'
    	return this.apiService.post(url, form)
    }

    put(id: string, form) : Observable<any> {
      form._method = 'PUT'
      let url = `/usuarios-clientes/${id}`
      return this.apiService.post(url, form)
    }
    
    get(id: string = null): Observable<any> {
      let url : string = id != null ? `/usuarios-clientes/${id}` : `/usuarios-clientes`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }
    
    getUsuariosCliente(idCliente?: string): Observable<any> {
      let url: string = idCliente ? `/cliente-usuarios/${idCliente}` : `/usuarios-clientes`
      return this.apiService.get(url)
    }
    
    restore(id: string): Observable<any> {
      return this.apiService.get(`/restaurar-usuario-cliente/${id}` )
    }

    delete(id:string): Observable<any> {
      let url = `/usuarios-clientes/${id}`
      return this.apiService.delete(url)
    }
}
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class RelatoriosService {
    constructor(private apiService: ApiService) {}
    
    getRelatoriosClientes(request): Observable<any> {
        let url : string = `/relatorios/clientes`
        return this.apiService.get(url, request).pipe(
            retry(5),
            catchError(() => {
              return EMPTY
            }),
            shareReplay()
          )
    }
    
    getProjetosRelatoriosClientes(request): Observable<any> {
        let url : string = `/relatorios/clientes/projetos`
        return this.apiService.get(url, request).pipe(
            retry(5),
            catchError(() => {
              return EMPTY
            }),
            shareReplay()
		);
    }
    
    getRelatoriosColaboradores(request): Observable<any> {
        let url : string = `/relatorios/colaboradores`
        return this.apiService.get(url, request).pipe(
            retry(5),
            catchError(() => {
              return EMPTY
            }),
            shareReplay()
          )
    }
    
    getRelatoriosSetores(request): Observable<any> {
        let url : string = `/relatorios/setores`
        return this.apiService.get(url, request).pipe(
            retry(5),
            catchError(() => {
              return EMPTY
            }),
            shareReplay()
          )
    }
}
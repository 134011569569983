import { Injectable } from '@angular/core'

import { Observable, EMPTY } from 'rxjs'
import { retry, catchError, shareReplay } from 'rxjs/operators'

import { ApiService } from './api.service'

@Injectable()
export class TabelaPostagensConteudoService {
    constructor(private apiService: ApiService) {}

	get(id: string = null): Observable<any> {
		let url : string
		url = id != null ? `/tabela-conteudo/${id}` : `/tabela-conteudos`;
		return this.apiService.get(url);
	}

	getAll(form?): Observable<any> {
		let url : string = `/tabela-conteudos`
		return this.apiService.get(url, form)
	}
	
    post(form) : Observable<any> {
		let url = '/tabela-conteudo';
		return this.apiService.post(url, form);
	}
	
    put(id, form) : Observable<any> {
		let url = '/tabela-conteudo/' + id;
		return this.apiService.post(url, form);
	}	

	delete(id): Observable<any> {
		let url = `/deletar/tabela-conteudo/${id}`;
		return this.apiService.post(url);
	}

	deleteEtapa(id, form): Observable<any> {
		let url = `/deletar-etapa/tabela-conteudo/${id}`;
		return this.apiService.post(url, form);
	}
	
	getEtapas(form): Observable<any> {
		let url : string
		url = `/etapa-conteudo`;
		return this.apiService.get(url, form);
	}

	getHistoricoEtapa(id): Observable<any> {
		let url : string
		url = `/historico-etapas/${id}`;
		return this.apiService.get(url);
	}
}
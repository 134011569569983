import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule, MatIconModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { LoginService } from './security/login/login.service';
import { LoginComponent } from './security/login/login.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { NgxLoadingModule } from 'ngx-loading';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { SafeHtmlPipe } from './safe-html.pipe';
import { HttpModule } from '@angular/http';


@NgModule({
	imports: [
		HttpModule,
		RouterModule,
		MatIconModule,
		NgxViacepModule,
		AppRoutingModule,
		ComponentsModule,
		HttpClientModule,
		NgxLoadingModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatProgressSpinnerModule,
		
	],
	declarations: [
		AppComponent,
		LoginComponent,
		AdminLayoutComponent,
		SafeHtmlPipe,
	],
	providers: [LoginService],
	bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class PropostaProdutosTerceiroService {
	constructor(private apiService: ApiService) { }

	post(form): Observable<any> {
		let url = '/proposta-produto-terceiro'
		return this.apiService.post(url, form)
	}

	put(id: string, form): Observable<any> {
		let url = `/proposta-produto-terceiro/${id}`
		return this.apiService.put(url, form)
	}

	getAllProposta(id_proposta): Observable<any> {
        return this.apiService.get(`/proposta-produto-terceiro/proposta/${id_proposta}`)
	}

	getAll(filtro?): Observable<any> {
        return this.apiService.get('/proposta-produtos-terceiro', filtro)
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/proposta-produto-terceiro/${id}`
		return this.apiService.get(url)
	}

	delete(id) {
		let url: string = `/proposta-produto-terceiro/${id}`
		return this.apiService.delete(url)
	}
}
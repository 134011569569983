import { Component, OnInit, ElementRef } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import {
	Location,
	LocationStrategy,
	PathLocationStrategy,
} from "@angular/common";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
	private listTitles: any[];
	private toggleButton: any;
	private sidebarVisible: boolean;

	location: Location;
	mobile_menu_visible: any = 0;
	name_user: string = "";

	constructor(
		location: Location,
		private element: ElementRef,
		private router: Router
	) {
		this.location = location;
		this.sidebarVisible = false;
	}

	ngOnInit() {
		this.listTitles = ROUTES.filter((listTitle) => listTitle);
		const navbar: HTMLElement = this.element.nativeElement;
		this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
		this.router.events.subscribe((event) => {
			this.sidebarClose();
			var $layer: any = document.getElementsByClassName("close-layer")[0];
			if ($layer) {
				$layer.remove();
				this.mobile_menu_visible = 0;
			}
		});
		this.name_user = JSON.parse(localStorage.getItem("usuario")).colaborador
			? JSON.parse(localStorage.getItem("usuario")).colaborador.nome
			: JSON.parse(localStorage.getItem("usuario")).usuario_cliente.nome;
	}

	dark() {
		$("head").append(
			$('<link rel="stylesheet" type="text/css" />').attr(
				"href",
				environment.apiUrl + "/style.css"
			)
		);
	}

	sidebarOpen() {
		const toggleButton = this.toggleButton;
		const body = document.getElementsByTagName("body")[0];
		setTimeout(function () {
			toggleButton.classList.add("toggled");
		}, 500);

		body.classList.add("nav-open");

		this.sidebarVisible = true;
	}

	sidebarClose() {
		const body = document.getElementsByTagName("body")[0];
		this.toggleButton.classList.remove("toggled");
		this.sidebarVisible = false;
		body.classList.remove("nav-open");
	}

	sidebarToggle() {
		// const toggleButton = this.toggleButton;
		// const body = document.getElementsByTagName('body')[0];
		var $toggle = document.getElementsByClassName("navbar-toggler")[0];

		if (this.sidebarVisible === false) {
			this.sidebarOpen();
		} else {
			this.sidebarClose();
		}
		const body = document.getElementsByTagName("body")[0];

		if (this.mobile_menu_visible == 1) {
			// $('html').removeClass('nav-open');
			body.classList.remove("nav-open");
			if ($layer) {
				$layer.remove();
			}
			setTimeout(function () {
				$toggle.classList.remove("toggled");
			}, 400);

			this.mobile_menu_visible = 0;
		} else {
			setTimeout(function () {
				$toggle.classList.add("toggled");
			}, 430);

			var $layer = document.createElement("div");
			$layer.setAttribute("class", "close-layer");

			if (body.querySelectorAll(".main-panel")) {
				document
					.getElementsByClassName("main-panel")[0]
					.appendChild($layer);
			} else if (body.classList.contains("off-canvas-sidebar")) {
				document
					.getElementsByClassName("wrapper-full-page")[0]
					.appendChild($layer);
			}

			setTimeout(function () {
				$layer.classList.add("visible");
			}, 100);

			$layer.onclick = function () {
				//asign a function
				body.classList.remove("nav-open");
				this.mobile_menu_visible = 0;
				$layer.classList.remove("visible");
				setTimeout(function () {
					$layer.remove();
					$toggle.classList.remove("toggled");
				}, 400);
			}.bind(this);

			body.classList.add("nav-open");
			this.mobile_menu_visible = 1;
		}
	}

	getTitle() {
		var titlee = this.location.prepareExternalUrl(this.location.path());

		if (titlee.charAt(0) === "#") {
			titlee = titlee.slice(1);
		}

		var newTitle = titlee.split("/")[1];

		if (this.listTitles.length > 0) {
			for (const rota of this.listTitles) {
				if (rota.path == `/${newTitle}`) {
					return rota.title;
				} else if (rota.children) {
					if (rota.children.length > 0) {
						for (const subRota of rota.children) {
							if (subRota.path == `/${newTitle}`) {
								return subRota.title;
							}
						}
					}
				}
			}
		}

		return "";

		// for(var item = 0; item < this.listTitles.length; item++){
		// 	if(this.listTitles[item].path === titlee){
		// 		return this.listTitles[item].title;
		// 	}
		// }
		// return 'Dashboard';
	}

	logout() {
		//Itens definidos no Dashboard
		localStorage.removeItem("filtroColaboradorDashboard");
		localStorage.removeItem("filtroSetorDashboard");
		localStorage.removeItem("filtroClienteDashboard");
		localStorage.removeItem("filtroUsuarioClienteDashboard");
		localStorage.removeItem("tipoTarefaListagem");

		//Itens definidos nos Projetos
		localStorage.removeItem("tipoFiltroProjetos");
		localStorage.removeItem("tipoFiltroProjetosColaborador");
		localStorage.removeItem("tipoFiltroProjetosCliente");
		localStorage.removeItem("tipoFiltroProjetosUsuarioCliente");
		localStorage.removeItem("statusProjeto");

		//Itens definidos na listagem das peças
		localStorage.removeItem("cliente");
		localStorage.removeItem("id_projeto");
		localStorage.removeItem("id_peca");
		localStorage.removeItem("status");
		localStorage.removeItem("data_inicio");
		localStorage.removeItem("data_fim");
		localStorage.removeItem("id_colaborador");
		localStorage.removeItem("id_setor_etapa");
		localStorage.removeItem("id_projeto_etapa");
		localStorage.removeItem("id_peca_etapa");
		localStorage.removeItem("status_etapa");
		localStorage.removeItem("data_inicio_etapa");
		localStorage.removeItem("data_fim_etapa");
		localStorage.removeItem("id_colaborador_etapa");

		localStorage.setItem("login", "false");
		localStorage.setItem("login_etapa", "false");

		this.router.navigate(["/"]);
	}
}

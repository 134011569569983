import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

@Injectable()

export class ModalService {

    private messageCloseAddHorasInDashboard = new Subject<boolean>()
    currentMessageCloseAddHorasInDashboard = this.messageCloseAddHorasInDashboard.asObservable()
    
    private messageCloseAddHorasInVisulizarTarefa = new Subject<boolean>()
    currentMessageCloseAddHorasInVisulizarTarefa = this.messageCloseAddHorasInVisulizarTarefa.asObservable()
    
    private messageCloseVisualizarTarefaInDashboard = new Subject<boolean>()
    currentMessageCloseVisualizarTarefaInDashboard = this.messageCloseVisualizarTarefaInDashboard.asObservable()
    
    private messageCloseVisualizarProjetoInDashboard = new Subject<boolean>()
    currentMessageCloseVisualizarProjetoInDashboard = this.messageCloseVisualizarProjetoInDashboard.asObservable()
    
    private messageCloseVisualizarTarefaInVisualizarProjeto = new Subject<boolean>()
    currentMessageCloseVisualizarTarefaInVisualizarProjeto = this.messageCloseVisualizarTarefaInVisualizarProjeto.asObservable()
    
    private messageCloseAddMensagemProjeto = new Subject<boolean>()
    currentMessageCloseAddMensagemProjeto = this.messageCloseAddMensagemProjeto.asObservable()
    
    private messageCloseAnexosTarefa = new Subject<boolean>()
    currentMessageCloseAnexosTarefa = this.messageCloseAnexosTarefa.asObservable()
    
    private messageCloseAnexosTarefaDashboard = new Subject<boolean>()
    currentMessageCloseAnexosTarefaDashboard = this.messageCloseAnexosTarefaDashboard.asObservable()
    
    private messageCloseAnexosProjeto = new Subject<boolean>()
    currentMessageCloseAnexosProjeto = this.messageCloseAnexosProjeto.asObservable()
    
    private messageCloseAnexosProjetoDashboard = new Subject<boolean>()
    currentMessageCloseAnexosProjetoDashboard = this.messageCloseAnexosProjetoDashboard.asObservable()
    
    private messageClosePermissoesModalToCadastroUsuariosClientes = new Subject<boolean>()
    currentMessageClosePermissoesModalToCadastroUsuariosClientes = this.messageClosePermissoesModalToCadastroUsuariosClientes.asObservable()
    
    private messageCloseAdicionarFormasCadastroMovimentosToCallerComponent = new Subject<boolean>()
    
    private messageCloseCadastroFinanceiroToProjetos = new Subject<boolean>()
    
    constructor() {}
    
    send_closeAddHorasInDashboard(message: boolean){
        this.messageCloseAddHorasInDashboard.next(message)
    }

    get_closeAddHorasInDashboard(): Observable<any> {
        return this.messageCloseAddHorasInDashboard.asObservable()
    }
    
    send_closeAddHorasInVisulizarTarefa(message: boolean){
        this.messageCloseAddHorasInVisulizarTarefa.next(message)
    }

    get_closeAddHorasInVisulizarTarefa(): Observable<any> {
        return this.messageCloseAddHorasInVisulizarTarefa.asObservable()
    }

    send_closeVisualizarTarefaInDashboard(message: boolean){
        this.messageCloseVisualizarTarefaInDashboard.next(message)
    }

    get_closeVisualizarTarefaInDashboard(): Observable<any> {
        return this.messageCloseVisualizarTarefaInDashboard.asObservable()
    }

    send_closeVisualizarProjetoInDashboard(message: boolean){
        this.messageCloseVisualizarProjetoInDashboard.next(message)
    }

    get_closeVisualizarProjetoInDashboard(): Observable<any> {
        return this.messageCloseVisualizarProjetoInDashboard.asObservable()
    }
    
    send_closeVisualizarTarefaInVisualizarProjeto(message: boolean){
        this.messageCloseVisualizarTarefaInVisualizarProjeto.next(message)
    }

    get_closeVisualizarTarefaInVisualizarProjeto(): Observable<any> {
        return this.messageCloseVisualizarTarefaInVisualizarProjeto.asObservable()
    }

    send_closeAddMensagemProjeto(message: boolean){
        this.messageCloseAddMensagemProjeto.next(message)
    }

    get_closeAddMensagemProjeto(): Observable<any> {
        return this.messageCloseAddMensagemProjeto.asObservable()
    }

    send_closeAnexosTarefa(message: boolean){
        this.messageCloseAnexosTarefa.next(message)
    }

    get_closeAnexosTarefa(): Observable<any> {
        return this.messageCloseAnexosTarefa.asObservable()
    }

    send_closeAnexosTarefaDashboard(message: boolean){
        this.messageCloseAnexosTarefaDashboard.next(message)
    }

    get_closeAnexosTarefaDashboard(): Observable<any> {
        return this.messageCloseAnexosTarefaDashboard.asObservable()
    }
    
    send_closeAnexosProjetos(message: boolean){
        this.messageCloseAnexosProjeto.next(message)
    }

    get_closeAnexosProjetos(): Observable<any> {
        return this.messageCloseAnexosProjeto.asObservable()
    }
    
    send_closeAnexosProjetoInProjetos(message: boolean){
        this.messageCloseAnexosProjetoDashboard.next(message)
    }

    get_closeAnexosProjetoInProjetos(): Observable<any> {
        return this.messageCloseAnexosProjetoDashboard.asObservable()
    }

    sendClosePermissoesModalToCadastroUsuariosClientes(message){
        this.messageClosePermissoesModalToCadastroUsuariosClientes.next(message)
    }
    
    getClosePermissoesModalToCadastroUsuariosClientes(){
        return this.messageClosePermissoesModalToCadastroUsuariosClientes.asObservable()
    }

    sendCloseAdicionarFormasCadastroMovimentosToCallerComponent(message){
        this.messageCloseAdicionarFormasCadastroMovimentosToCallerComponent.next(message)
    }
    
    getCloseAdicionarFormasCadastroMovimentosToCallerComponent(){
        return this.messageCloseAdicionarFormasCadastroMovimentosToCallerComponent.asObservable()
    }

    sendCloseCadastroFinanceiroToProjetos(message){
        this.messageCloseCadastroFinanceiroToProjetos.next(message)
    }
    
    getCloseCadastroFinanceiroToProjetos(){
        return this.messageCloseCadastroFinanceiroToProjetos.asObservable()
    }
}
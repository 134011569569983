import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class NotificacoesInteracoesTarefasService {
	constructor(private apiService: ApiService) {}

	getTodasFiltro(filtro): Observable<any> {
        return this.apiService.get('/notificacoes-tarefas/todas/filtro', filtro)
	}
	
	getTotalNotificacoesTarefas(filtro): Observable<any> {
        return this.apiService.get('/total-notificacoes-tarefas', filtro)
    }
	
	getTotalNotificacoesTarefasProjetos(filtro): Observable<any> {
        return this.apiService.get('/total-notificacoes-tarefas-projetos', filtro)
	}
	
	lerNotificacoes(form: any) : Observable<any> {
		let url: string = `/notificacoes-tarefas/ler/notificacoes`
		return this.apiService.post(url, form)
	}
	
	lerNotificacao(form: any) : Observable<any> {
		let url: string = `notificacoes-tarefas/ler/notificacao/id`
		return this.apiService.post(url, form)
	}
}
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams, HttpClient } from '@angular/common/http'

@Injectable()

export class ProjetosService {
    constructor(
      private apiService: ApiService,
		  private httpClient: HttpClient
    ) {}

    post(form) : Observable<any> {
		let url = '/projetos'
      	return this.apiService.post(url, form)
    }

    postMensagem(idProjeto: string, formMensagem: any): Observable<any> {
		let url: string = `/mensagem-projeto/${idProjeto}`
		return this.apiService.post(url, formMensagem)
    }

    put(id: string, form) : Observable<any> {
    	form._method = 'PUT'
		let url = `/projetos/${id}`
      	return this.apiService.post(url, form)
    }
    
    get(id: string): Observable<any> {
      let url : string = `/projeto/${id}`
      return this.apiService.get(url)
    }

    getAll(status?: string, idColaborador?: string, form?): Observable<any> {
      let filtros = form && form.id_cliente ? new HttpParams().set('id_cliente', form.id_cliente) : null
      if(form && form.projetos_acesso_cliente)
        filtros = filtros ? filtros.set('projetos_acesso_cliente', form.projetos_acesso_cliente) : new HttpParams().set('projetos_acesso_cliente', form.projetos_acesso_cliente)
      return this.apiService.get(`/projetos/${status}/${idColaborador}`, filtros)
    }

    getTodosProjetos(form?): Observable<any> {
      let url : string = `/projetos-todos`
      return this.apiService.get(url, form)
    }

    getProjetosSelect(form?): Observable<any> {
      let url : string = `/projetos-select`
      return this.apiService.get(url, form)
    }

    getAllSelect(status?: string, form?): Observable<any> {
      let filtros = form && form.id_cliente ? new HttpParams().set('id_cliente', form.id_cliente) : null
      if(form && form.projetos_acesso_cliente)
        filtros = filtros ? filtros.set('projetos_acesso_cliente', form.projetos_acesso_cliente) : new HttpParams().set('projetos_acesso_cliente', form.projetos_acesso_cliente)
      return this.apiService.get(`/projetos-select/${status}`, filtros)
    }

    getInativos(): Observable<any> {
      let url : string = `/projetos-inativos`
      return this.apiService.get(url)
    }
    getInativosColaborador(idColaborador: string = null): Observable<any> {
      let url : string = `/projetos-inativos-colaborador/${idColaborador}`
      return this.apiService.get(url)
    }
    getInativosCliente(idCliente: string): Observable<any> {
      let url : string = `/projetos-inativos-cliente/${idCliente}`
      return this.apiService.get(url)
    }
    getInativosTipoProjeto(idTipoProjeto: string): Observable<any> {
      let url : string = `/projetos-inativos-tipo-projeto/${idTipoProjeto}`
      return this.apiService.get(url)
    }

    getProjetosColaborador(status: string, idColaborador: string, form?: any): Observable<any> {
      let filtros = form.id_cliente ? new HttpParams().set('id_cliente', form.id_cliente) : null
      return this.apiService.get(`/projetos-colaborador/${status}/${idColaborador}`, filtros)
    }

    getProjetosCliente(status: string, idColaborador: string){
      let url : string = `/projetos-cliente/${status}/${idColaborador}`
      return this.apiService.get(url)
	}
	
    getProjetosTipoProjeto(status: string, idTipoProjeto: string, idCliente: string){
		var filtroCliente = ''
		if (idCliente != '') {
			filtroCliente = `/${idCliente}`
		} else {
			filtroCliente = `/0`
		} 

      	let url : string = `/projetos-tipo-projeto/${status}/${idTipoProjeto}${filtroCliente}`
      	return this.apiService.get(url)
    }
    
    getProjetosUsuarioCliente(status: string, idUsuarioCliente: string){
      let url : string = `/projetos-usuario-cliente/${status}/${idUsuarioCliente}`
      return this.apiService.get(url)
    }
    
    getProjetosFiltroBusca(form){
      let url : string = `/projetos-filtro-busca`
      return this.apiService.post(url, form)
	}
	
	getProjetosDashboard(filtro): Observable<any> {
        return this.apiService.get('/projetos-dashboard', filtro)
    }

    encerrarProjeto(idProjeto: string): Observable<any> {
      return this.apiService.get(`/encerrar-projeto/${idProjeto}` )
    }
    
    restore(idProjeto: string): Observable<any> {
      return this.apiService.get(`/restaurar-projeto/${idProjeto}` )
    }
    
    restoreInativo(idProjeto: string): Observable<any> {
      return this.apiService.get(`/restaurar-projeto-inativo/${idProjeto}` )
    }
    
    delete(id:string): Observable<any> {
      let url = `/projetos/${id}`
      return this.apiService.delete(url)
    }
    
    getProjetosOrcamento(form): Observable<any> {
      let url = `/projetos-orcamento`
      return this.apiService.get(url, form)
    }

    enviarAnexos(idProjeto: string, form: any): Observable<any>{
      let url: string = `/projetos-anexos/${idProjeto}`
      return this.apiService.post(url, form)
    }

    deleteAnexos(idAnexo: string): Observable<any>{
      let url: string = `/projetos-anexos/${idAnexo}`
      return this.apiService.delete(url)
    }

    deleteMensagem(idMensagem: string){
      let url: string = `/mensagem-projeto/${idMensagem}`
      return this.apiService.delete(url)
    }
    
    deleteMensagemUsuarioCliente(idMensagem: string){
      let url: string = `/mensagem-usuario-cliente-projeto/${idMensagem}`
      return this.apiService.delete(url)
    }
}
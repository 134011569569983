import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class OrdemServicoService {
	constructor(private apiService: ApiService) { }

	put(id: string, form): Observable<any> {
		let url = `/ordem-servico/${id}`
		return this.apiService.put(url, form)
	}

	delete(id) {
		let url: string = `/ordem-servico-financeiro/${id}`
		return this.apiService.delete(url)
	}

	getAll(filtro?): Observable<any> {
        return this.apiService.get('/ordens-servico', filtro)
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/ordem-servico/${id}`
		return this.apiService.get(url)
	}

	pdf(id: string, formato: string): Observable<any> {
        return this.apiService.get(`/pdf-ordem-servico/${id}/${formato}`);
	}
}
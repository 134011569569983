   import { Injectable } from '@angular/core'
   import { Observable } from 'rxjs'
   import { ApiService } from './api.service'
   import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http'
   import { environment } from '../../../environments/environment'
   
   @Injectable()
   
   export class ProjetosRecorrentesService {
    constructor(
        private apiService: ApiService,
        private httpClient: HttpClient
        ) {}
        
        post(form) : Observable<any> {
            let url = '/projetos-recorrentes'
            return this.apiService.post(url, form)
        }
        
        put(id: string, form) : Observable<any> {
            let url = `/projetos-recorrentes/${id}`
            return this.apiService.post(url, form)
        }
        
        get(id: string = null): Observable<any> {
            let url : string = id == null ? `/projetos-recorrentes` : `/projetos-recorrentes/${id}`
            return this.apiService.get(url)
        }
        
        getProjetosRecorrentesColaborador(idColaborador: string): Observable<any> {
            let url : string = `/projetos-recorrentes-colaborador/${idColaborador}`
            return this.apiService.get(url)
        }
        
        getProjetosRecorrentesCliente(idCliente: string): Observable<any> {
            let url : string = `/projetos-recorrentes-cliente/${idCliente}`
            return this.apiService.get(url)
        }        
        
        getProjetosRecorrentesTiposProjeto(idTipoProjeto: string): Observable<any> {
            let url : string = `/projetos-recorrentes-tipos-projeto/${idTipoProjeto}`
            return this.apiService.get(url)
        }        
        
        restore(idProjeto: string): Observable<any> {
            return this.apiService.get(`/restaurar-projeto-recorrente/${idProjeto}` )
        }
        
        delete(id:string): Observable<any> {
            let url = `/projetos-recorrentes/${id}`
            return this.apiService.delete(url)
        }
        
        enviarAnexos(idProjeto: string, form: any): Observable<any>{
            let url: string = `/projetos-recorrentes-anexos/${idProjeto}`
            return this.apiService.post(url, form)
        }
        
        deleteAnexos(idAnexo: string): Observable<any>{
            let url: string = `/projetos-recorrentes-anexos/${idAnexo}`
            return this.apiService.delete(url)
        }

        lancarRecorrente(idProjetoRecorrente: string): Observable<any>{
            let url: string = `/lancar-projeto-recorrente/${idProjetoRecorrente}`
            return this.apiService.post(url)
        }
    }
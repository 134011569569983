import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'

import { ApiService } from './api.service'
import { environment } from '../../../environments/environment'

@Injectable()

export class ProdutosTerceiroService {
	constructor(private apiService: ApiService) {}
	
	post(form) : Observable<any> {
		let url = '/produtos-terceiro';
		return this.apiService.post(url, form);
	}
	
	put(id: string, form) : Observable<any> {
		let url = `/produtos-terceiro/${id}`;
		return this.apiService.post(url, form);
	}
	
	getAll(status) {
		let url: string = `/produtos-terceiros/${status}`;
		return this.apiService.get(url);
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/produtos-terceiro/${id}`;
		return this.apiService.get(url);
	}
	
	restore(id: string): Observable<any> {
		return this.apiService.get(`/restaurar-produtos-terceiro/${id}`);
	}
	
	delete(id:string): Observable<any> {
		let url = `/produtos-terceiro/${id}`;
		return this.apiService.delete(url);
	}
}
import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class PermissoesService {
    constructor(private apiService: ApiService) {
    }
    
    getPermissoes(id?): Observable<any> {
        let url : string = id ? `/permissoes-cadastros/agrupados/editar/${id}` : `/permissoes-cadastros/agrupados/criar`
        return this.apiService.get(url)
    }

    buscarApenasPermissoesCadastros(idUsuario: number, modulo?: string): Observable<any> {
        let url : string = `/permissoes-cadastros/${idUsuario}`
        return this.apiService.get(url, new HttpParams().set('modulo', modulo))
    }

    buscarApenasPermissoesEspecificas(idUsuario: number, modulos: any): Observable<any> {
        // let url : string =  
        return this.apiService.get(`/permissoes-especificas/${idUsuario}`, modulos)
    }

    getPermissao(id: string){
        let url : string = `/permissoes-cadastros/${id}`
        return this.apiService.get(url)
    }
    
}
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class ClientesService {
    constructor(private apiService: ApiService) {}

    post(formCliente) : Observable<any> {
      let url = '/clientes'
      return this.apiService.post(url, formCliente)
    }
	
    put(id: string, formCliente) : Observable<any> {
		  let url = `/clientes/${id}`
      return this.apiService.post(url, formCliente)
    }


    getAll(form) {
      let url : string = `/allClientesFilter`
      return this.apiService.get(url, form)
    }
    
    get(id: string = null): Observable<any> {
      let url : string = id != null ? `/clientes/${id}` : `/clientes`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getSelect(id: string = null): Observable<any> {
      let url : string = id != null ? `/clientes/${id}` : `/clientes-select`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getSearch(search: string, status: string = '0'): Observable<any> {
      let url : string = `/clientes?q=${search}&status=${status}`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    getInativos(): Observable<any> {
      let url : string = `/clientes-inativos`
      return this.apiService.get(url).pipe(
        retry(5),
        catchError(() => {
          return EMPTY
        }),
        shareReplay()
      )
    }

    delete(id:string): Observable<any> {
      let url = `/clientes/${id}`
      return this.apiService.delete(url)
    }

    restoreInativo(id: string): Observable<any> {
      return this.apiService.get(`/restaurar-cliente/${id}` )
    }
}
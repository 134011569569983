import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Utils } from '../../../assets/ts/utils'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	
	formLogin : FormGroup;
	loading: boolean = false

    constructor( 
		private fb: FormBuilder ,
		private router: Router,
		private httpClient: HttpClient
	) { }
    
    ngOnInit() {
		this.formLogin = this.fb.group({
			usuario: ['', Validators.required],
			senha: ['', Validators.required]
		})
		if(localStorage.getItem('usuario')){
			localStorage.removeItem('usuario')
			window.location.reload(true)
		}
    }
    
	async onSubmit() {
		try {
			this.loading = true
			let response = await this.httpClient.post(environment.apiUrl + '/auth/login',this.formLogin.value).toPromise()
			if(response['access_token']){
				localStorage.removeItem('access_token')
				localStorage.removeItem('usuario')		
				localStorage.setItem('access_token', response['access_token'])
				
				try {
					let response2 = await this.httpClient.post(environment.apiUrl + '/auth/me', this.formLogin.value, { headers: Utils.buscarTokenAutenticacao() }).toPromise()
					localStorage.setItem('usuario', JSON.stringify(response2))
	
					//Itens definidos no Dashboard
					localStorage.removeItem('filtroColaboradorDashboard')
					localStorage.removeItem('filtroSetorDashboard')
					localStorage.removeItem('filtroClienteDashboard')
					localStorage.removeItem('filtroUsuarioClienteDashboard')
					localStorage.removeItem('tipoTarefaListagem')
	
					//Itens definidos nos Projetos
					localStorage.removeItem('tipoFiltroProjetos')
					localStorage.removeItem('tipoFiltroProjetosColaborador')
					localStorage.removeItem('tipoFiltroProjetosCliente')
					localStorage.removeItem('tipoFiltroProjetosUsuarioCliente')
					localStorage.removeItem('statusProjeto')

					//Itens definidos na listagem das peças
					localStorage.removeItem('cliente')
					localStorage.removeItem('id_projeto')
					localStorage.removeItem('id_peca')
					localStorage.removeItem('status')
					localStorage.removeItem('data_inicio')
					localStorage.removeItem('data_fim')
					localStorage.removeItem('id_colaborador')
					localStorage.removeItem('id_setor_etapa')
					localStorage.removeItem('id_projeto_etapa')
					localStorage.removeItem('id_peca_etapa')
					localStorage.removeItem('status_etapa')
					localStorage.removeItem('data_inicio_etapa')
					localStorage.removeItem('data_fim_etapa')
					localStorage.removeItem('id_colaborador_etapa')
			
					localStorage.setItem('login', 'false' )
					localStorage.setItem('login_etapa', 'false' )


					
					if (response2['colaborador'] == null) {
						this.router.navigate(['dashboard'])
					} else {
						this.router.navigate(['dashboard'])
					}
				} catch (error) {
					Utils.showNotification('warning', error.error.error)
					this.loading = false
				}
			}
		} catch (error) {
			Utils.showNotification('warning', error.error.error);
			this.loading = false
		}
    }
    
}

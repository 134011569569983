import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class OrcamentoProdutosTerceiroService {
	constructor(private apiService: ApiService) { }

	post(form): Observable<any> {
		let url = '/orcamento-produto-terceiro'
		return this.apiService.post(url, form)
	}

	put(id: string, form): Observable<any> {
		let url = `/orcamento-produto-terceiro/${id}`
		return this.apiService.put(url, form)
	}

	getAllOrcamento(id_orcamento): Observable<any> {
        return this.apiService.get(`/orcamento-produto-terceiro/orcamento/${id_orcamento}`)
	}

	getAllProjeto(id_projeto): Observable<any> {
        return this.apiService.get(`/orcamento-produto-terceiro/projeto/${id_projeto}`)
	}

	getById(id: string = null): Observable<any> {
		let url: string = `/orcamento-produto-terceiro/${id}`
		return this.apiService.get(url)
	}

	delete(id) {
		let url: string = `/orcamento-produto-terceiro/${id}`
		return this.apiService.delete(url)
	}
}
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class LoginService {
    constructor(private apiService: ApiService) {

    }

    login(formLogin): Observable<any> {
		  return this.apiService.post('/auth/login', formLogin)
    }

}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from "@angular/common/http";

@Injectable()
export class EstadosService {
    constructor(private apiService: ApiService) {}

    getEstados(): Observable<any> {
		return this.apiService.get('/estados');
    }

}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from "@angular/common/http";

@Injectable()
export class CidadesService {
    constructor(private apiService: ApiService) {

    }

    getCidades(uf_estado): Observable<any> {
		  return this.apiService.get('/cidades-estado/'+uf_estado);
    }

}
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'

@Injectable()

export class TiposProjetoService {
	constructor(private apiService: ApiService) {}

	post(formTipoProjeto) : Observable<any> {
		let url = '/tipos-projeto'
		return this.apiService.post(url, formTipoProjeto)
	}
	  
	put(id: string, formTipoProjeto) : Observable<any> {
		let url = `/tipos-projeto/${id}`
		return this.apiService.put(url, formTipoProjeto)
	}
	  
	get(id: string = null, ativo: string = '1'): Observable<any> {
		let url : string = id != null ? `/tipos-projeto/${id}` : `/tipos-projeto-status/${ativo}`
		return this.apiService.get(url).pipe(
			retry(5),
			catchError(() => {
			return EMPTY
			}),
			shareReplay()
		)
	}
  
	delete(id:string): Observable<any> {
		let url = `/tipos-projeto/${id}`
		return this.apiService.delete(url)
	}
  
	restoreInativo(id: string): Observable<any> {
		return this.apiService.get(`/restaurar-tipos-projeto/${id}` )
	}

	/**
	 * Requisições referentes aos avisos de tipos de projeto
	 */

	getAvisoId(id: string = null): Observable<any> {
		let url : string = `/tipo-projeto-avisos/${id}`
		return this.apiService.get(url).pipe(
			retry(5),
			catchError(() => {
			return EMPTY
			}),
			shareReplay()
		)
	}

	getAvisosTipoProjeto(id): Observable<any> {
		return this.apiService.get('/tipo-projeto-avisos', id)
	}
	
	postAvisosTipoProjeto(formTipoProjeto) : Observable<any> {
		let url = '/tipo-projeto-avisos'
		return this.apiService.post(url, formTipoProjeto)
	}
	  
	putAvisosTipoProjeto(id: string, form) : Observable<any> {
		let url = `/tipo-projeto-avisos/${id}`
		return this.apiService.put(url, form)
	}

	deleteAvisoTipoProjeto(id:string): Observable<any> {
		let url = `/tipo-projeto-avisos/${id}`
		return this.apiService.delete(url)
	}
}
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	// apiUrl: 'http://localhost:8000',
	// apiUrl: 'http://localhost:8888/sistema-projetos/api/public/',
	// apiUrl: 'http://localhost/sistema-projetos/api/public',
	
	// AWS 
	apiUrl: 'https://projetos.webupplay.com.br/api/public',
	// apiUrl: 'https://teste-projetos.webupplay.com.br/api/public',
	// apiUrl: 'https://projetos-restrito.webupplay.com.br/api/public',
	version: '3.5',
	production: true

};
import { Injectable } from '@angular/core'
import { Observable, EMPTY } from 'rxjs'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { retry, catchError, shareReplay } from 'rxjs/operators'
@Injectable()

export class TarefasRecorrentesService {
    constructor(private apiService: ApiService) {}

    post(form) : Observable<any> {
		let url = '/tarefas-recorrentes'
		return this.apiService.post(url, form)
    }

    put(id: string, form) : Observable<any> {
		let url = `/tarefas-recorrentes/${id}`
		return this.apiService.post(url, form)
    }
    
    get(id: string = null): Observable<any> {
		let url : string
		url = id != null ? `/tarefas-recorrentes/${id}` : `/tarefas-recorrentes`
		return this.apiService.get(url)
    }

    getTarefasRecorrentesProjeto(idProjeto: string): Observable<any> {
      	return this.apiService.get(`/tarefas-recorrentes-projeto/${idProjeto}`)
    }

    delete(id:string): Observable<any> {
		let url = `/tarefas-recorrentes/${id}`
		return this.apiService.delete(url)
    }

    enviarAnexos(idTarefa: string, form: any): Observable<any>{
		let url: string = `/tarefas-recorrentes-anexos/${idTarefa}`
		return this.apiService.post(url, form)
    }

    deleteAnexos(idAnexo: string): Observable<any>{
		let url: string = `/tarefas-recorrentes-anexos/${idAnexo}`
		return this.apiService.delete(url)
	}
	
	// Respostas da Peça/Tarefa
	getRespostas(id_tarefa): Observable<any> { 
		return this.apiService.get(`/respostas-peca-tarefa-recorrente/${id_tarefa}`).pipe(
			retry(5),
			catchError(() => {
				return EMPTY
			}),
			shareReplay()
		)
	}
}